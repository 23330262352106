import http from "../axios/http"
//公海客户列表
export let getList = (params) => {
    return http.get("/crm/get_index", { params })
}
//添加客户
export let postAdd = (params) => {
    return http.post("/crm/customer_insert", params)
}
//删除客户
export let postDelete = (params) => {
    return http.post("/crm/public_customer_delete", params)
}
// //公海退回公海
// export let postBack = (params) => {
//     return http.post("/crm/rollback_public", params)
// }
//更新公海客户
export let postUpdate = (params) => {
    return http.post("/crm/public_customer_update", params)
}
//通话记录
export let postRecord = (params) => {
    return http.get("/crm/private_customer_record", {params})
}
//通话号码记录详情（已做）
export let getRecordItem = (params) => {
    return http.get("/number/record", { params })
}
export let postImport = (params) => {
    return http.post("/crm/custom_import", params)
}
//公海客户分配到私海
export let postInsert = (params) => {
    return http.post("/crm/public_customer_allot_insert", params)
}
//话术列表
export let getSpeechList = (params) => {
    return http.get("/speech/speech_list", { params })
}
//领取公海客户到私海
export let getCustomer = (params) => {
    return http.get("/crm/public_customer_receive_insert", { params })
}
//跟进记录
export let getFollowList = (params) => {
    return http.get("/crm/follow_list", { params })
}
//添加跟进记录
export let postFollowInsert = (params) => {
    return http.post("/crm/follow_insert", params)
}
